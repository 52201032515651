import { api } from "@data-phone/react-resource-server-auth";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isPossiblePhoneNumber } from "libphonenumber-js";
import qs from "qs";

const initialState = {
    data: []
};

export const fetchCallHistoryLookup = createAsyncThunk("items/fetchCallHistory", async data => {
    const othernum = data.callDirection === "inbound" ? data.callerIDNumber : data.destination;
    const ournum = data.callDirection === "inbound" ? data.destination : data.callerIDNumber;

    if (!othernum || !isPossiblePhoneNumber(othernum, "US")) {
        return;
    }

    const response = await api.get(`${process.env.REACT_APP_CDR_BASE_API_URL}/cdr?${qs.stringify({ filters: { othernum, ournum, limit: 5, offset: 0 } },{encode: false})}`);

    return { records: response.data.records, direction: data.callDirection, id: data.callUUID };
});

const callHistorySlice = createSlice({
    name: "callHistory",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchCallHistoryLookup.fulfilled, (state, action) => {
            if (!action.payload) {
                return;
            }
            state.data.push(action.payload);
        });
    }
});

export default callHistorySlice.reducer;
