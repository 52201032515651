import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addData, removeData, updateData } from "redux/slices/callDetailsSlice";
import { useSocket } from "@data-phone/react-resource-server-auth";
import { useIntegrationLookups } from "./useIntegrationLookups";
import { phoneNumberFormatE164 } from "helper";
import { isPossiblePhoneNumber } from "libphonenumber-js";
import { useHistoryLookups } from "./useHistoryLookups";

const useCallSocket = () => {
    const dispatch = useDispatch();
    const socketRef = useRef(null);
    const ivrMenus = useSelector(state => state.ivrMenus.items);
    const ringGroups = useSelector(state => state.ringGroups.items);
    const extensions = useSelector(state => state.extensions.items);
    const integrations = useSelector(state => state.integrations.items);
    const [intitialCallsToLookup, setInitialCallsToLookup] = useState([]);

    useEffect(() => {
        if (integrations && integrations.length > 0 && integrations.every(item => item.config && Object.keys(item.config).length > 0) && intitialCallsToLookup.length > 0) {
            fetchIntegrationLookups(intitialCallsToLookup);
            setInitialCallsToLookup([]);
        }
    }, [integrations]);

    const { socket } = useSocket({
        socketUrl: `${process.env.REACT_APP_LIVE_CALL_WSS}/activeCalls`,
        path: ``
    });

    const { fetchIntegrationLookups } = useIntegrationLookups();
    const { fetchCallHistory } = useHistoryLookups();

    const handleRecord = (data, action) => {
        if (socketRef.current) {
            socketRef.current.emit("record", data, action, err => {
                if (err) {
                    console.error("Error while performing record action:", err);
                }
            });
        }
    };

    const handleBridgeCall = (uuid1, uuid2) => {
        if (socketRef.current) {
            socketRef.current.emit("bridge", uuid1, uuid2, err => {
                if (err) {
                    console.error("Error while performing bridge action:", err);
                }
            });
        }
    };

    useEffect(() => {
        if (socket) {
            socket.emit("get-calls");
            if (socket.connected) {
                console.log("client connected call");
            }
            socket.on("get-calls", data => {
                let requiredData = [];
                if (data.length == 0) {
                    return;
                }
                const numbersToLookup = data
                    .map(item => item.callerIDNumber)
                    .filter(item => isPossiblePhoneNumber(item, "US"))
                    .map(item => phoneNumberFormatE164(item));
                setInitialCallsToLookup(numbersToLookup);
                data.forEach(call => {
                    // outgoing
                    if (
                        extensions?.result?.some(item => item.extension == call.callerIDNumber) ||
                        ivrMenus?.result?.some(item => item.extension == call.callerIDNumber) ||
                        ringGroups?.result?.some(item => item.extension == call.callerIDNumber)
                    ) {
                        call.isOutgoing = true;
                        requiredData.push(call);
                    } else {
                        if (call.callees.length > 0) {
                            if (call.callees.some(item => item.answerState == "answered")) {
                                let index = call.callees.findIndex(item => item.answerState == "answered");
                                if (index !== -1) {
                                    call.answerState = "answered";
                                    call.destination = call.callees[index].number;
                                }
                                requiredData.push(call);
                            } else {
                                requiredData.push(call);
                            }
                        } else {
                            requiredData.push(call);
                        }
                    }
                });
                if(data.callDirection){
                    fetchCallHistory(data);
                }
                dispatch(addData(requiredData));
            });

            socket.on("connect_error", async err => {
                console.log("connection err", err);
                console.error(err.message);
            });

            socket.on("disconnect", () => {
                console.log("client disconnected", new Date());
            });

            socket.on("call-destrxoy", data => {
                dispatch(removeData(data));
            });
            socket.on("call-update", data => {
                if (isPossiblePhoneNumber(data.callerIDNumber, "US")) {
                    fetchIntegrationLookups(phoneNumberFormatE164(data.callerIDNumber));
                } // outgoing
                if (data.callDirection) {
                    fetchCallHistory(data);
                }
                if (
                    extensions?.result?.some(item => item.extension == data.callerIDNumber) ||
                    ivrMenus?.result?.some(item => item.extension == data.callerIDNumber) ||
                    ringGroups?.result?.some(item => item.extension == data.callerIDNumber)
                ) {
                    data.isOutgoing = true;
                    dispatch(updateData(data));
                } else {
                    //incoming
                    if (data.callees.length > 0) {
                        if (data.callees.some(item => item.answerState == "answered")) {
                            let index = data.callees.findIndex(item => item.answerState == "answered");
                            if (index !== -1) {
                                data.answerState = "answered";
                                data.destination = data.callees[index].number;
                            }
                            dispatch(updateData(data));
                        } else {
                            dispatch(updateData(data));
                        }
                    } else {
                        dispatch(updateData(data));
                    }
                }

                console.log("call-update", data);
            });

            socket.on("auth-failure", data => {
                console.log("auth-failure");
                console.log(data);
            });

            socket.on("close", () => {
                console.log("client closed");
            });

            socketRef.current = socket;
        }

        return () => {
            if (socketRef.current) {
                socket.disconnect();
                socketRef.current = null;
            }
        };
    }, [socket]);

    return { handleRecord: handleRecord, handleBridgeCall: handleBridgeCall };
};

export default useCallSocket;
