import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const dataSlice = createSlice({
  name: "callDetails",
  initialState,
  reducers: {
    addData: (state, action) => {
      state.data = action.payload;
    },
    updateData: (state, action) => {
      const index = state.data.findIndex(
        (item) => item.callUUID === action.payload.callUUID
      );

      if (index !== -1) {
        if (action.payload.answerState == "hangup") {
          state.data.splice(index, 1);
        } else {
          state.data[index] = action.payload;
        }
      } else {
        if (action.payload.answerState !== "hangup") {

          state.data.push(action.payload);
        }
      }

      // const index = state.data.findIndex(
      //   (item) => item.uuid === action.payload.channels[0].uuid
      // );
      // if (index !== -1) {
      //   if (action.payload.channels[0].callState == "hangup") {
      //     state.data.splice(index, 1);
      //   } else {
      //     state.data[index] = action.payload.channels[0];
      //   }
      // } else {
      //   state.data.push(action.payload.channels[0]);
      // }
    },

    removeData: (state, action) => {
      const index = state.data.findIndex(
        (item) => item.callUUID === action.payload
      );
      if (index !== -1) {
        state.data.splice(index, 1);
      }
    },
    removeAllLiveActivityCalls: (state) => {
      state.data = [];
    }
  },
});

export const { addData, updateData, removeData,removeAllLiveActivityCalls } = dataSlice.actions;
export default dataSlice.reducer;