import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCallHistoryLookup } from "redux/slices/call-history/callHistory";

export const useHistoryLookups = () => {
    const dispatch = useDispatch();
    const fetchedCallerIDs = useRef(new Set());

    return {
        fetchCallHistory: data => {
            if (!data.callUUID) {
                return;
            }

            if (fetchedCallerIDs.current.has(data.callUUID)) {
                return;
            }

            fetchedCallerIDs.current.add(data.callUUID);

            dispatch(fetchCallHistoryLookup(data));
        }
    };
};

export const useGetHistoryLookups = () => {
    const callHistoryRef = useRef(null);

    const callHistory = useSelector(state => state.callHistory.data);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        callHistoryRef.current = callHistory;
        setIsFetching(!isFetching);
    }, [callHistory]);

    return {
        getCallHistoryData: id => {
            if (!callHistoryRef.current || callHistoryRef.current.length === 0) {
                return;
            }
            const data = callHistoryRef.current.find(item => item.id === id);
            return data;
        }
    };
};
