import { formatNumberToSeconds } from "helper";
import moment from "moment";
import timerImg from "../../images/images/ico-timer.svg";
import ico_incoming from "../../images/images/ico-call-incoming.svg";
import ico_outgoing from "../../images/images/ico-call-outgoing.svg";
import { FaHistory } from "react-icons/fa";


export const CallHistory = ({ callHistoryData, isHovered, setIsHovered }) => {
    return (
        <div className="relative inline-block" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <p className="text-xs ml-2">
                <FaHistory color="#686868" />
            </p>
            {isHovered && callHistoryData.length > 0 && (
                <div style={{ width: "500px", marginTop: "30px" }} className="absolute left-0 mt-2  bg-white shadow-lg rounded-lg p-3 z-50">
                    <ul>
                        {callHistoryData.map((call, index) => (
                            <li key={index} className="mb-2 border-b last:border-none pb-2 last:pb-0">
                                <div className="flex items-center justify-between">
                                    <div className="icon">
                                        <img src={call.call_direction == "outbound" ? ico_outgoing : ico_incoming} alt="" />
                                    </div>
                                    <div className="flex items-center">
                                        <div className="counter mx-1 text-xs font-normal py-1 px-2 rounded">{call.caller_id_name || ""}</div>
                                    </div>
                                    <div className="counter mx-1 text-xs font-normal py-1 px-2 rounded">{call.dialed_number || ""}</div>
                                    <div>
                                        {call.missed_call ? (
                                            <p className="text-xs text-gray-500">Missed Call</p>
                                        ) : (
                                            <p className="text-xs">
                                                <span className="font-semibold pr-1">Answered Call</span>
                                            </p>
                                        )}
                                    </div>
                                    <div className="timer flex items-center place-content-between py-1 px-2 rounded text-xs font-medium">
                                        <span>
                                            {" "}
                                            <img src={timerImg} alt="" />
                                        </span>
                                        <span className="pl-1">{formatNumberToSeconds(call.duration)}</span>
                                    </div>
                                </div>
                                <p className="text-xs mt-2">{moment(call.start_timestamp).format("MM/DD/YYYY HH:mm")}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};
